import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@/constants/colors';

export type TypographyType =
  | 'Heading1Large'
  | 'Heading1'
  | 'Heading2Big'
  | 'Heading2'
  | 'Heading3Big'
  | 'Heading3'
  | 'Heading4'
  | 'Heading5'
  | 'PRegular'
  | 'PRegularBold'
  | 'PSmall'
  | 'PMicro'
  | 'PMedium'
  | 'Switch'
  | 'PMini'
  | 'PMicroSM'
  | 'PMicroXS'
  | 'PMicroMD'
  | 'PLittle';

export type TypographyAs =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'b'
  | 'strong'
  | 'span'
  | 'a';

type TypographyComponentProps = {
  children?: React.ReactNode;
  $color?: string;
  $colorXs?: string;
  title?: string;
  $fontSizeSm?: string;
  $fontSizeXs?: string;
  $breakpoint?: string;
  className?: string;
  $weight?: string;
  href?: string;
  id?: string;
  onClick?: () => void;
  as?: TypographyAs;
  $type?: TypographyType;
  $lineHeight?: BreakpointsType;
  $defaultstyle?: DefaultStyleProps;
  dangerouslySetInnerHTML?: { __html: string | undefined };
};

type BreakpointsType = { sm?: string; xs?: string };

type DefaultStyleProps = {
  color: string;
  colorXs?: string;
  lineHeight: BreakpointsType;
  fontSize: BreakpointsType;
  fontWeight?: string;
  fontStyle?: string;
  padding?: string;
  margin?: string;
  fontSizeXs?: string;
  fontSizeSm?: string;
};

export type StyleTypographyType = {
  Heading1Large: DefaultStyleProps;
  Heading1: DefaultStyleProps;
  Heading2: DefaultStyleProps;
  Heading2Big: DefaultStyleProps;
  Heading3Big: DefaultStyleProps;
  Heading3: DefaultStyleProps;
  Heading4: DefaultStyleProps;
  Heading5: DefaultStyleProps;
  PRegular: DefaultStyleProps;
  PRegularBold: DefaultStyleProps;
  PSmall: DefaultStyleProps;
  PMicro: DefaultStyleProps;
  PMedium: DefaultStyleProps;
  Switch: DefaultStyleProps;
  PMini: DefaultStyleProps;
  PLittle: DefaultStyleProps;
  PMicroSM: DefaultStyleProps;
  PMicroXS: DefaultStyleProps;
  PMicroMD: DefaultStyleProps;
};

export const DEFAULT_TYPOGRAPHY_STYLES: StyleTypographyType = {
  Heading1Large: {
    color: COLORS.EBONY,
    lineHeight: { sm: '170%', xs: '40px' },
    fontSize: { sm: '3.125vw', xs: '1.75rem' }, //45px
    fontWeight: '700',
  },
  Heading1: {
    color: COLORS.EBONY,
    lineHeight: { sm: '170%', xs: '40px' },
    fontSize: { sm: '2.431vw', xs: '1.563rem' }, //35px
    fontWeight: '700',
  },
  Heading2Big: {
    color: COLORS.EBONY,
    lineHeight: { sm: '170%', xs: '28.8px' },
    fontSize: { sm: '1.944vw', xs: '1.125rem' }, //28px
    fontWeight: '700',
  },
  Heading2: {
    color: COLORS.EBONY,
    lineHeight: { sm: '170%', xs: '30.4px' },
    fontSize: { sm: '1.875vw', xs: '1.188rem' }, //27px
    fontWeight: '700',
  },
  Switch: {
    color: COLORS.EBONY,
    lineHeight: { sm: '170%', xs: '36.8px' },
    fontSize: { sm: '1.875vw', xs: '1.438rem' }, //27px
    fontWeight: '700',
  },
  Heading3Big: {
    color: COLORS.EBONY,
    lineHeight: { sm: '160%', xs: '27px' },
    fontSize: { sm: '1.736vw', xs: '1.25rem' }, //25px
    fontWeight: '700',
  },
  Heading3: {
    color: COLORS.EBONY,
    lineHeight: { sm: '160%', xs: '27px' },
    fontSize: { sm: '1.528vw', xs: '1.1rem' }, //22px
    fontWeight: '400',
  },
  Heading4: {
    color: COLORS.EBONY,
    lineHeight: { sm: '160%', xs: '27px' },
    fontSize: { sm: '1.389vw', xs: '1rem' }, //20px
    fontWeight: '400',
  },
  Heading5: {
    color: COLORS.EBONY,
    lineHeight: { sm: '19px', xs: '14px' },
    fontSize: { sm: '1rem', xs: '0.75rem' },
    fontWeight: 'bold',
  },
  PRegular: {
    color: COLORS.EBONY,
    lineHeight: { sm: '150%', xs: '22.4px' },
    fontSize: { sm: '1.25vw', xs: '0.875rem' }, //18px
    fontWeight: '400',
  },
  PRegularBold: {
    color: COLORS.EBONY,
    lineHeight: { sm: '32px', xs: '28.8px' },
    fontSize: { sm: '1.25rem', xs: '1.125rem' },
    fontWeight: '700',
  },
  PMedium: {
    color: COLORS.EBONY,
    lineHeight: { sm: '172%', xs: '24px' },
    fontSize: { sm: '1.111vw', xs: '1rem' }, //16px
    fontWeight: '400',
  },
  PSmall: {
    color: COLORS.EBONY,
    lineHeight: { sm: '140%', xs: '20px' },
    fontSize: { sm: '0.972vw', xs: '0.813rem' }, //14px
    fontWeight: '400',
  },
  PLittle: {
    color: COLORS.EBONY,
    lineHeight: { sm: '120%', xs: '20px' },
    fontSize: { sm: '0.903vw', xs: '0.813rem' }, //13px
    fontWeight: '400',
  },
  PMini: {
    color: COLORS.EBONY,
    lineHeight: { sm: '140%', xs: '14px' },
    fontSize: { sm: '0.833vw', xs: '0.625rem' }, //12px
    fontWeight: '500',
  },
  PMicro: {
    color: COLORS.EBONY,
    lineHeight: { sm: '120%', xs: '14.4px' },
    fontSize: { sm: '0.764vw', xs: '0.563rem' }, //11px
    fontWeight: '400',
  },
  PMicroMD: {
    color: COLORS.EBONY,
    lineHeight: { sm: '120%', xs: '14px' },
    fontSize: { sm: '0.625vw', xs: '0.563rem' }, //9px
    fontWeight: '400',
  },
  PMicroSM: {
    color: COLORS.EBONY,
    lineHeight: { sm: '110%', xs: '14px' },
    fontSize: { sm: '0.486vw', xs: '0.438rem' }, //7px
    fontWeight: '400',
  },
  PMicroXS: {
    color: COLORS.EBONY,
    lineHeight: { sm: '100%', xs: '14px' },
    fontSize: { sm: '0.347vw', xs: '0.313rem' }, //5px
    fontWeight: '400',
  },
};

const StyledText = styled.p<TypographyComponentProps>`
  color: ${(props) => props.$defaultstyle?.color || 'black'};
  line-height: ${(props) => props.$defaultstyle?.lineHeight?.sm};
  font-size: ${(props) =>
    props.$defaultstyle?.fontSizeSm || props.$defaultstyle?.fontSize?.sm};
  font-weight: ${(props) => props?.$weight || props.$defaultstyle?.fontWeight};
  font-style: ${(props) => props.$defaultstyle?.fontStyle};
  @media (max-width: ${(props) => props.$breakpoint + 'px'}) {
    color: ${(props) =>
      props.$defaultstyle?.colorXs || props.$defaultstyle?.color || 'black'};
    font-size: ${(props) =>
      props.$defaultstyle?.fontSizeXs || props.$defaultstyle?.fontSize?.xs};
    line-height: ${(props) => props.$defaultstyle?.lineHeight?.xs};
  }
`;

export const Typography = ({
  title,
  onClick = () => {},
  children,
  $fontSizeSm = '',
  $fontSizeXs = '',
  $color = '',
  $colorXs,
  $type = 'PRegular',
  $weight,
  as = 'p',
  className = '',
  $breakpoint = '1024',
  $lineHeight,
  dangerouslySetInnerHTML,
  id,
  ...rest
}: TypographyComponentProps) => {
  const defaultstyle =
    {
      ...DEFAULT_TYPOGRAPHY_STYLES[$type],
      color: $color,
      colorXs: $colorXs,
      fontSizeSm: $fontSizeSm,
      fontSizeXs: $fontSizeXs,
      lineHeight: {
        sm: $lineHeight?.sm || DEFAULT_TYPOGRAPHY_STYLES[$type].lineHeight.sm,
        xs: $lineHeight?.xs || DEFAULT_TYPOGRAPHY_STYLES[$type].lineHeight.xs,
      },
    } || {};

  return (
    <StyledText
      id={id}
      className={`${className} p-0 m-0`}
      onClick={onClick}
      title={title}
      as={as}
      $defaultstyle={defaultstyle}
      $breakpoint={$breakpoint}
      $weight={$weight}
      {...rest}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </StyledText>
  );
};
