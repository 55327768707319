import React, { ReactElement } from 'react';
import {
  PATH_ABOUT,
  PATH_AGENT,
  PATH_ARTICLES,
  PATH_BI,
  PATH_CUSTOMER,
  PATH_FAQ,
  PATH_INTEGRATION,
} from '@/constants/spa-routes';
import { AgentCoSupportIcon } from '@/assets/icons/agentCoSupportIcon';
import { CustomerCoSupportIcon } from '@/assets/icons/customerCoSupportIcon';
import { BiCoSupportIcon } from '@/assets/icons/biCoSupportIcon';
import { ZendeskLogo } from '@/assets/icons/zendeskLogo';
import { FreshdeskLogo } from '@/assets/icons/freshdeskLogo';
import { SlackLogo } from '@/assets/icons/slackLogo';

export type NavigationItemsType = {
  id: number;
  title: string;
  href: string;
  dropdownItems?: {
    id: number;
    icon?: string | ReactElement;
    title: string;
    href: string;
    description?: ReactElement;
    comingSoon?: boolean;
  }[];
}[];

export const navigationItems: NavigationItemsType = [
  {
    id: 0,
    title: 'Products',
    href: '',
    dropdownItems: [
      {
        id: 10,
        title: 'CoSupport Agent',
        href: PATH_AGENT.ROOT,
        icon: <AgentCoSupportIcon />,
        description: (
          <>
            AI Agent Assistant Designed
            <br /> for Customer Support Teams
          </>
        ),
        comingSoon: false,
      },
      {
        id: 11,
        title: 'CoSupport Customer',
        href: PATH_CUSTOMER.ROOT,
        icon: <CustomerCoSupportIcon />,
        description: (
          <>
            Self-sufficient AI for customer
            <br /> service
          </>
        ),
        comingSoon: false,
      },
      {
        id: 12,
        title: 'CoSupport BI',
        href: PATH_BI.ROOT,
        icon: <BiCoSupportIcon />,
        description: (
          <>
            AI assistant for internal use
            <br /> within your organization
          </>
        ),
        comingSoon: false,
      },
    ],
  },
  {
    id: 1,
    title: 'Integrations',
    href: '',
    dropdownItems: [
      {
        id: 22,
        title: 'Zendesk',
        icon: <ZendeskLogo />,
        description: (
          <>
            One of the oldest cloud-based
            <br /> customer service platforms
          </>
        ),
        href: PATH_INTEGRATION.INTEGRATION_ZENDESK,
        comingSoon: false,
      },
      {
        id: 23,
        title: 'Freshdesk',
        icon: <FreshdeskLogo />,
        description: (
          <>
            Cloud-based software designed
            <br /> to improve customer support
          </>
        ),
        href: PATH_INTEGRATION.INTEGRATION_FRESHDESK,
        comingSoon: false,
      },
      {
        id: 33,
        title: 'Slack',
        icon: <SlackLogo />,
        description: (
          <>
            Business team communication
            <br />
            platform
          </>
        ),
        href: '',
        comingSoon: true,
      },
    ],
  },
  {
    id: 2,
    title: 'Articles',
    href: PATH_ARTICLES.ROOT,
  },
  {
    id: 3,
    title: 'About Us',
    href: PATH_ABOUT.ROOT,
  },
  {
    id: 4,
    title: 'FAQ',
    href: PATH_FAQ.ROOT,
  },
];
